import Styles from "./couponcard.module.css";
import { useNavigate } from "react-router-dom";
const Couponcard = (props) => {
  console.log(props);
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => {
          navigate(`/store/${props.name.replace(/ /g, "-")}`);
          window.scrollTo(0, 0);
        }}
        className={`p-3 ${Styles.Couponcard}`}
      >
        <div className={`${Styles.imgccon}`}>
          <div className={`${Styles.container}`}>
            <div className={`${Styles.imageWrapper}`}>
              <img
                className={`${Styles.img}`}
                src={`https://backend.discountsmagnet.com/uploads/${props.img}`}
                alt="Image"
              />
            </div>
          </div>
        </div>
        <h2 className={`mt-4 font18 fontw7`}>{props.name}</h2>
        <p>
          {props.coupon.length > 65
            ? props.coupon.slice(0, 63) + "..."
            : props.coupon}
        </p>
        <button
          className={`${Styles.cardbtn} ${
            props.coupon.length < 30 ? "mt-4" : ""
          }`}
        >
          Shop Now
        </button>
      </div>
    </>
  );
};
export default Couponcard;
