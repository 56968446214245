import jwtDecode from "jwt-decode";

const jwtCheck = (e) => {
  if (
    localStorage.getItem("access token") &&
    jwtDecode(localStorage.getItem("access token")) &&
    jwtDecode(localStorage.getItem("access token")).user_type == e
  ) {
    console.log(jwtDecode(localStorage.getItem("access token")));
    const expiry = jwtDecode(localStorage.getItem("access token")).exp;
    const now = new Date();
    return now.getTime() > expiry * 1000 ? false : true;
  }
  // return false;
};

export default jwtCheck;
