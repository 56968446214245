import { useState } from "react";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const [email, setemail] = useState();
  const [password, setpassword] = useState();
  const navigate = useNavigate();
  return (
    <>
      <input
        placeholder="email"
        onChange={(e) => {
          setemail(e.target.value);
        }}
      />
      <input
        placeholder="password"
        onChange={(e) => {
          setpassword(e.target.value);
        }}
      />
      <button
        onClick={() => {
          axios
            .post("https://backend.discountsmagnet.com/login", {
              email: email,
              password: password,
            })
            .then((response) => {
              console.log(response.data);
              localStorage.setItem("access token", response.data.token);
              if (response.data.status === 200) {
                navigate("/dashboard");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }}
      >
        submit
      </button>
    </>
  );
};
export default Login;
