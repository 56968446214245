import React, { useEffect, useState } from "react";

function SitemapGenerator() {
  const [sitemapURL, setSitemapURL] = useState(null);

  useEffect(() => {
    // Make a GET request to the API endpoint
    fetch("https://backend.discountsmagnet.com/storeslug")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Assuming the API returns the sitemap file URL
        setSitemapURL(data.url); // Set the sitemap URL in state
      })
      .catch((error) => {
        console.error("Error fetching sitemap:", error);
        // Handle the error here
      });
  }, []);

  return (
    <div>
      {sitemapURL ? (
        <div>
          <p>
            Sitemap generated successfully. You can download it from the
            following link:
          </p>
          <a href={sitemapURL} download>
            Download Sitemap
          </a>
        </div>
      ) : (
        <p>Generating sitemap...</p>
      )}
    </div>
  );
}

export default SitemapGenerator;
