import React from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Styles from "./app.module.css";
import Home from "./pages/home/home";
import Store from "./pages/store/store";
import MainStore from "./pages/mainStore/mainstore";
import DashboardOut from "./components/DashboardOut/DashboardOut";
import Affiliatestores from "./pages/admin/Affiliate Stores/affiliatestores";
import Addstore from "./pages/admin/addstore/addstore";
import Offers from "./pages/admin/Offers/Offers";
import Addoffer from "./pages/admin/Addoffer/Addoffer";
import Categories from "./pages/admin/Categories/Categories";
import Networks from "./pages/admin/Networks/Networks";
import Login from "./pages/authpage/login";
import Editestore from "./pages/admin/editestore/editestore";
import Editeoffer from "./pages/admin/editeoffer/editeoffer";
import UploadComponent from "./components/uploadcom/uploadcom";
import SitemapGenerator from "./components/sitemap/sitemap";
const SearchSelect = (props) => {
  const [results, setResults] = useState(props.options);

  const searchList = (event) => {
    const filteredResults = props.filterMethod(
      props.options,
      event.target.value
    );
    setResults(filteredResults);
  };

  return props.render({
    results,
    searchList,
  });
};
const Autocomplete = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [storeData, setstoreData] = useState([]);
  const [newState, setNewState] = useState([]);
  const dropdownRef = useRef();

  useEffect(() => {
    axios
      .get("https://backend.discountsmagnet.com/store")
      .then((response) => {
        sliceData(response.data.details);
        setstoreData(response.data.details);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // This will run whenever newState changes
    // console.log(newState, "kashif12");
  }, [newState]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        hideDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const sliceData = (data) => {
    let names = [];
    for (let x = 0; x < data.length; x++) {
      names.push(data[x].name);
    }
    setNewState(names);
  };

  const filterMethod = (options, query) => {
    return options.filter((option) =>
      option.toLowerCase().includes(query.toLowerCase())
    );
  };

  const showDropdown = () => {
    setDropdownVisible(true);
  };

  const hideDropdown = () => {
    setDropdownVisible(false);
  };

  const handleOptionClick = () => {
    hideDropdown();
  };

  return (
    <>
      {newState.length > 0 ? (
        <SearchSelect
          options={newState}
          filterMethod={filterMethod}
          render={({ results, searchList }) => (
            <div className={Styles.autocomplete} ref={dropdownRef}>
              <div className={`${Styles.serchin}`}>
                <span className={`me-1 ${Styles.iconContainer}`}>
                  <Icon icon="charm:search" />
                </span>{" "}
                <input
                  type="text"
                  className={`${Styles.autocompleteserach} `}
                  placeholder="Type to search store"
                  onChange={searchList}
                  onFocus={showDropdown}
                />
              </div>

              {dropdownVisible && (
                <div className={Styles.autocomplete_dropdown}>
                  <ul className={Styles.autocomplete_search_results_list}>
                    {results.length > 0 &&
                      results.map((result, index) => (
                        <li
                          className={Styles.autocomplete_search_result}
                          key={result}
                          onClick={handleOptionClick}
                        >
                          <a
                            className="an"
                            href={`/store/${storeData[index].slug}`}
                          >
                            {result}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        />
      ) : (
        "loading"
      )}
    </>
  );
};

function NotFound() {
  return (
    <div className="page404">
      <div className="text-center ">
        <h1 className="font150 fontw7 fe">
          4<span className="red">0</span>4
        </h1>
        <p className="font21 fontw4">
          OOPS, THE PAGE YOU ARE LOOKING FOR CAN'T BE FOUND!
        </p>
        <div className="page404con mb-3">
          <Autocomplete />{" "}
        </div>
        <a href="/" className="page404a">
          {" "}
          Return To Homepage
        </a>
      </div>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/store/:storeId" element={<Store />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sitemapgenerator" element={<SitemapGenerator />} />
        <Route path="/mainstore" element={<MainStore />} />
        <Route path="/dashboard" element={<DashboardOut />}>
          <Route path="affiliatestores" element={<Affiliatestores />} />
          <Route path="addstore" element={<Addstore />} />
          <Route path="offers" element={<Offers />} />
          <Route path="addoffer" element={<Addoffer />} />
          <Route path="categories" element={<Categories />} />
          <Route path="networks" element={<Networks />} />
          <Route path="editstore/:storeId" element={<Editestore />} />
          <Route path="editeoffer/:storeId" element={<Editeoffer />} />
          <Route path="uploadcomponent" element={<UploadComponent />} />
        </Route>
        <Route path="*" element={<NotFound />} />{" "}
        {/* Catch-all route for 404 */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
