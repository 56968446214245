import Styles from "./storecoupen.module.css";
import vre from "../../assets/material-symbols_verified.png";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
const StoreCoupon = (props) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    console.log("sdadasdasdasfasf");
    axios
      .put(`https://backend.discountsmagnet.com/uesed/${props.id}`)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className={` row d-flex align-items-center p-3 mb-3 ${Styles.card}`}>
        <div
          className={` col-4 me-2 d-flex justify-content-center align-items-center ${Styles.offer}`}
        >
          <p className="text-center lh C71 m-0 font40 fontw7">
            {props.discount_type === "deal" ? "DEAL" : props.thumbnail}
          </p>
        </div>
        <div className={`col-sm-7 col-12 ${Styles.rel}`}>
          <div className="d-flex align-items-center mb-2">
            {props.status === "verified" ? (
              <Icon
                className="DF me-1"
                icon="ic:round-verified"
                style={{ fontSize: "24px" }}
              />
            ) : (
              <Icon
                className="D73 me-1"
                icon="ic:round-verified"
                style={{ fontSize: "24px", color: "#9D9D9D" }}
              />
            )}
            {props.status === "verified" ? (
              <p className="font16 fontw6 DF m-0">Verified Coupons</p>
            ) : (
              <p className="font16 fontw6 D73 m-0"> Unverified Coupons</p>
            )}
          </div>
          <p className="font20 fontw5 mb-2 m-0">{props.title}</p>
          <p className="font18 fontw3 mb-2 m-0">{props.description}</p>
          <p className="font16 fontw6 B1 mb-2 m-0">{props.used} - Uses</p>
          <div></div>
          {props.discount_type === "deal" ? (
            <button
              onClick={() => {
                window.open(`${props.link}`, "_blank");
                handleCopy();
              }}
              className={`p-0 ${Styles.btndeal2}`}
            >
              Get Deal
            </button>
          ) : (
            <button
              onClick={() => {
                window.open(`${props.link}`, "_blank");
              }}
              data-bs-toggle="modal"
              data-bs-target={`${`#` + props.id}`}
              className={`p-0 ${Styles.btn2}`}
            >
              ****{props.coupon_code}
            </button>
          )}
        </div>
        {props.discount_type === "deal" ? (
          <button
            onClick={() => {
              window.open(`${props.link}`, "_blank");
              handleCopy();
            }}
            className={`p-0 ${Styles.btndeal}`}
          >
            Get Deal
          </button>
        ) : (
          <Modalbtn
            title={props.title}
            link={props.link}
            id={props.id}
            coupon_code={props.coupon_code}
          />
        )}

        {/* <div className={`row d-flex justify-content-between ${Styles.conta} `}>
                  
                </div> */}

        <div
          className="modal fade"
          id={`${props.id}`}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  as
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body px-5">
                <div
                  className={`d-flex justify-content-between align-items-center px-2 ${Styles.coponcode}`}
                >
                  <p id={`${props.id}`} className="m-0">
                    {props.coupon_code}{" "}
                  </p>
                  <CopyToClipboard text={props.coupon_code} onCopy={handleCopy}>
                    <button className={`${Styles.copybtn} copy-button`}>
                      {!copied ? "Copy" : "copied"}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <a>
                  Go to Store{" "}
                  <span>
                    <Icon icon="humbleicons:external-link" />
                  </span>
                </a>
                <div className="d-flex justify-content-between">
                  <p className="m-0 me-3">Did This Offer Work??</p>
                  <Icon
                    className="font24 C71 me-2"
                    icon="fluent:thumb-like-24-filled"
                  />
                  <Icon
                    className="font24 red"
                    icon="fluent:thumb-dislike-16-filled"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Modalbtn = (props) => {
  const [copied, setCopied] = useState(false);
  const [datas, setdatas] = useState();
  const handleCopy = () => {
    setCopied(true);
    axios
      .put(`https://backend.discountsmagnet.com/uesed/${props.id}`)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div
        data-bs-toggle="modal"
        data-bs-target={`${`#` + props.id}`}
        className={`col-2 d-flex justify-content-center align-items-center  ${Styles.rel1}`}
      >
        <button
          onClick={() => {
            window.open(`${props.link}`, "_blank");
          }}
          className={`p-0 ${Styles.btn}`}
        >
          ****{props.coupon_code.substring(0, 8)}
        </button>
      </div>
      <div
        className="modal fade"
        id={`${props.id}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {props.title}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body px-5">
              <div
                className={`d-flex justify-content-between align-items-center px-2 ${Styles.coponcode}`}
              >
                <p id={`${props.id}`} className="m-0">
                  {props.coupon_code}
                </p>
                <CopyToClipboard text={props.coupon_code} onCopy={handleCopy}>
                  <button className={`${Styles.copybtn} copy-button`}>
                    {!copied ? "Copy" : "copied"}
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <a>
                Go to Store{" "}
                <span>
                  <Icon icon="humbleicons:external-link" />
                </span>
              </a>
              <div className="d-flex justify-content-between">
                <p className="m-0 me-3">Did This Offer Work??</p>
                <Icon
                  className="font24 C71 me-2"
                  icon="fluent:thumb-like-24-filled"
                />
                <Icon
                  className="font24 red"
                  icon="fluent:thumb-dislike-16-filled"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StoreCoupon;
