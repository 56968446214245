import { Carousel } from "react-responsive-carousel";
import React, { useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Styles from "./Carousel.module.css"
import bannerr from "../../assets/bgraphic.png"
const CarouselBanner = ()=>{
    const carouselRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);

    const handlePanStart = (event) => {
        setStartX(event.center.x);
        setStartY(event.center.y);
    };

    const handlePan = (event) => {
        const deltaX = event.center.x - startX;
        const deltaY = event.center.y - startY;

        // Calculate the absolute change in X and Y directions
        const absDeltaX = Math.abs(deltaX);
        const absDeltaY = Math.abs(deltaY);

        if (absDeltaX > absDeltaY) {
            // Horizontal swipe
            if (carouselRef.current) {
                carouselRef.current.handleSwipe(event); // Continue with carousel swipe behavior
            }
        } else {
            // Vertical swipe
            // You can add custom vertical swipe behavior here, if needed
        }
    };

    return(
        <div className={`${Styles.outerDiv} container`}>

            
         <Carousel
                ref={(ref) => {
                    carouselRef.current = ref;
                }}
                showStatus={false}
                showThumbs={false}
                // autoPlay
                // infiniteLoop
                // interval={3000}
                showIndicators={false} // Remove the dots
                swipeable={true} // Make it draggable
                selectedItem={currentIndex}
                onChange={(index) => setCurrentIndex(index)}
                renderArrowPrev={() => null} // This line removes the previous arrow
                renderArrowNext={() => null} // This line removes the next arrow
            >
                <div className={`${Styles.maindiv}  my-5 `}>
                <div className={` row d-flex align-items-center    `}>
                  <div className="col-md-6  p-4  p-md-5 text-start">
                    <h2 className={`${Styles.croHeading}`}>Get The Best Offers From Popular Store</h2>
                    <p className={`${Styles.croParagrap}`}>Explore unbeatable deals from your favorite top-tier stores saving big today!</p>
                    <button className={`${Styles.expbtn}`}>Explore Now</button>
                  </div>
                  <div className={`col-md-5   ${Styles.imgContiner}` }>
                    {/* <img className={`${Styles.bannerimg}`} src={bannerr}/> */}
                    </div>
                </div>
                </div>
             
          
          
            
            </Carousel>
        </div>
    )
} 
export default CarouselBanner