import Styles from "./DashboardOut.module.css"
import logo from "../../assets/RLOGO0-01 2.svg"
import { Icon } from '@iconify/react';
import jwtCheck from "../../system/jwtChecker";
import {
    Routes,
    Route,
    useNavigate,
    useHistory,
    NavLink,
    Link,
    Outlet,
} from "react-router-dom";
import { useEffect } from "react"

const DashboardOut = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (jwtCheck(1) === false) {
            navigate("/login");
        }
    }, [])
    return (
        <div className={`d-flex ${Styles.dashmain}`}>
            <div className={` w-20  ${Styles.sider} pt-4 `}>
                <div className="w-100">
                    <div className="d-flex  justify-content-center w-100 align-items-center">
                        <img src={logo} />
                        <h2 className="wit font20 fontw7">SalesUpto</h2>
                    </div>
                    <div className="d-flex flex-column  pt-4  justify-content-center w-100 align-items-center">

                        <a className={`wit font18 an2 w-100 ps-5 py-2 ${Styles.linkscon}`}> <span><Icon className="font23 me-2" icon="akar-icons:dashboard" /></span> Dashboard</a>

                        <a onClick={() => { navigate("affiliatestores") }} className={`wit font18 an2 w-100 ps-5  py-2 ${Styles.linkscon}`}><Icon className="font23 me-2" icon="clarity:store-line" /> Affiliate Stores</a>

                        <a onClick={() => { navigate("offers") }} className={`wit font18 an2 w-100 ps-5  py-2 ${Styles.linkscon}`}><Icon className="font23 me-2" icon="mdi:coupon-outline" />Offers</a>

                        <a onClick={() => { navigate("categories") }} className={`wit font18 an2 w-100 ps-5  py-2 ${Styles.linkscon}`}><Icon className="font23 me-2" icon="lucide:network" />Categories</a>

                        <a onClick={() => { navigate("networks") }} className={`wit font18 an2 w-100 ps-5  py-2 ${Styles.linkscon}`}><Icon className="font23 me-2" icon="fluent:database-link-24-regular" />Network</a>


                    </div>
                </div>
            </div>
            <div className="w-100"><Outlet /></div>
        </div>
    )
}
export default DashboardOut;