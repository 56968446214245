import Navbar from "../../components/Navbar/navbar";
import Styles from "./store.module.css";
import { Icon } from "@iconify/react";
import { Rating } from "react-simple-star-rating";
import { useEffect, useState } from "react";
import StoreCoupon from "../../components/StoreCoupen/storecoupen";
import Footer from "../../components/Footer/footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import CustomRating from "../../components/cusRating/cusRating";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
const Store = () => {
  const { storeId } = useParams();
  const [ratingValue, setRatingValue] = useState(0);
  const [storeData, setstoreData] = useState([]);
  const [offerData, setofferData] = useState([]);
  const [discontType, setdiscontType] = useState("none");
  const [ratingst, setratingst] = useState();
  const [ratingst1, setratingst1] = useState();
  const [emaill, setemail] = useState();
  const [idrating, setidrating] = useState();
  const [lcategory, setcategory] = useState("");
  const [mCategory, setMCategory] = useState("");
  const [storebc, setstorebc] = useState("");
  const [discontcount, setdiscontcount] = useState("");
  const navigate = useNavigate();
  const [iom, setiom] = useState(
    "https://static.shareasale.com/image/8612/200x200_1.jpg"
  );
  const [showToast, setShowToast] = useState(true);

  const names = [
    "Liam",
    "Emma",
    "Noah",
    "Olivia",
    "Oliver",
    "Ava",
    "Elijah",
    "Sophia",
    "James",
    "Isabella",
    "William",
    "Mia",
    "Benjamin",
    "Amelia",
    "Lucas",
    "Harper",
    "Henry",
    "Evelyn",
    "Alexander",
    "Abigail",
    "Jackson",
    "Ella",
    "Sebastian",
    "Scarlett",
    "Aiden",
    "Grace",
    "Matthew",
    "Lily",
    "Samuel",
    "Aria",
    "David",
    "Chloe",
    "Joseph",
    "Zoey",
    "Carter",
    "Stella",
    "Wyatt",
    "Natalie",
    "John",
    "Hazel",
    "Daniel",
    "Ellie",
    "Gabriel",
    "Nora",
    "Anthony",
    "Addie",
    "Isaac",
    "Lucy",
    "Nathan",
    "Sarah",
    "Christian",
    "Anna",
    "Leo",
    "Aurora",
    "Jaxon",
    "Brooklyn",
    "Eli",
    "Claire",
    "Charles",
    "Skylar",
  ];
  const toastdiscounts = [
    "10% discount",
    "15% discount",
    "20% discount",
    "25% discount",
    "30% discount",
    "35% discount",
    "40% discount",
    "50% discount",
    "$5 off",
    "$10 off",
    "$15 off",
    "$20 off",
    "$25 off",
    "$30 off",
    "$50 off",
    "Buy 1 get 1 free",
    "Free shipping",
    "20% off your next purchase",
    "10% off sitewide",
    "15% off on orders over $50",
  ];

  useEffect(() => {
    const showToast = () => {
      toast(
        `🟢 A user has successfully activated ${
          toastdiscounts[Math.floor(Math.random() * toastdiscounts.length)]
        }.`,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    };

    const intervalId = setInterval(() => {
      const randomDelay = Math.floor(Math.random() * 10000) + 5000; // Random delay between 5s and 15s
      setTimeout(showToast, randomDelay);
    }, 10000); // Runs every 10 seconds to trigger a random delay

    return () => clearInterval(intervalId);
  }, []);

  const ratingValuea =
    storeData.total_average_rating !== undefined
      ? storeData.total_average_rating.toFixed(1)
      : "";
  function handleVerify() {
    const emailInput = document.getElementById("emailInput");
    const email = emailInput.value;

    if (emailInput.checkValidity()) {
      // Email is valid

      axios
        .post(`https://backend.discountsmagnet.com/rating/${idrating}`, {
          email: email,
          rating_num: ratingst,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === 200) {
            NotificationManager.success(
              "Success message",
              "Review Added Successfully"
            );
          } else if (response.data.status === 409) {
            NotificationManager.warning(
              "warning message",
              "Rating already exists on this email"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // Perform additional actions or validations
    } else {
      // Email is not valid
      console.log("Invalid email");
      NotificationManager.warning("warning message", "Invalid email");
      // Display an error message or handle the invalid email case
    }
  }

  // const getSameStores = (category) => {
  //   axios
  //     .get(`https://backend.discountsmagnet.com/storecatas/${category}`)
  //     .then((response) => {
  //       console.log(response.data.details, "islos", category);
  //       setstorebc(response.data.details);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  useEffect(() => {
    console.log(storeId, "storeId");

    axios
      .get(
        `https://backend.discountsmagnet.com/storen/${storeId.replace(
          /-/g,
          "%20"
        )}`
      )
      .then((response) => {
        console.log(response);
        // getSameStores(response.data.details.category);
        setMCategory(response.data.details.category);
        setstoreData(response.data.details);
        setcategory(response.data.details.category);
        setidrating(response.data.details.id);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          navigate("*");
        }
      });
    axios
      .get(
        `https://backend.discountsmagnet.com/storedistype/${storeId.replace(
          /-/g,
          "%20"
        )} `
      )
      .then((response) => {
        setdiscontcount(response.data.stores[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `https://backend.discountsmagnet.com/filteroffer/${storeData.name}/${discontType}`
      )
      .then((response) => {
        setofferData(response.data.details);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [discontType, storeData]);
  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log("MCATEGORY!!! ", mCategory);
  //     axios
  //       .get(`https://backend.discountsmagnet.com/store/${lcategory}`)
  //       .then((response) => {
  //         console.log(response.data.details, "islos", lcategory);

  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }, 9000);
  // }, [])
  const customIcons = [
    { icon: <Icon icon="ic:round-star-rate" style={{ fontSize: "33px" }} /> },
    { icon: <Icon icon="ic:round-star-rate" style={{ fontSize: "33px" }} /> },
    { icon: <Icon icon="ic:round-star-rate" style={{ fontSize: "33px" }} /> },
    { icon: <Icon icon="ic:round-star-rate" style={{ fontSize: "33px" }} /> },
    { icon: <Icon icon="ic:round-star-rate" style={{ fontSize: "33px" }} /> },
  ];
  const handleRatingChange = (newRating) => {
    console.log("New rating:", newRating);
    setratingst(newRating);
  };
  const handleRating = (rate: number) => {
    setratingst(rate);
    console.log("New rating:", rate);
  };
  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{storeData.meta_title}</title>
        <meta name="description" content={`${storeData.meta_description}`} />
        <meta name="title" content={`${storeData.meta_title}`} />

        <meta charSet="utf-8" />
        <meta name="keywords" content="react, meta, document, html, tags" />
      </Helmet>
      <NotificationContainer />
      <div className={`${Styles.storemain}`}>
        <Navbar />
        <div className="container mt-50">
          <div className="row">
            <div className="col-md-12 col-lg-3">
              <div className={`${Styles.desktop}`}>
                <div className={`${Styles.maimg}`}>
                  <img
                    src={`https://backend.discountsmagnet.com/uploads/${storeData.upload_img}`}
                    alt={storeData.alt_img}
                  />
                </div>
                <button
                  onClick={() => {
                    window.open(`${storeData.link}`, "_blank");
                  }}
                  className={`mt-3 ${Styles.storebtn}`}
                >
                  <span className="me-1">
                    <Icon
                      style={{ fontSize: "23px" }}
                      icon="clarity:store-line"
                    />
                  </span>
                  Visit Store
                </button>
                <div className={`mt-3  ${Styles.storedetails}`}>
                  <div className={`px-2 py-2 ${Styles.hr}`}>
                    <h2 className="font18 fontw5 m-0">
                      {storeData.name} - Rate It
                    </h2>
                  </div>
                  <div className={`px-2 py-3 ${Styles.hr}`}>
                    <div
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      className="mb-2"
                    >
                      <Rating type="button" customIcons={customIcons} />
                      {/* <CustomRating initialRating={1} /> */}
                    </div>

                    <div
                      class="modal fade"
                      id="staticBackdrop"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h1
                              class="modal-title fs-5"
                              id="staticBackdropLabel"
                            >
                              Rating
                            </h1>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body d-flex justify-content-center align-items-center">
                            <div>
                              <Rating
                                type="button"
                                customIcons={customIcons}
                                onClick={handleRating}
                              />

                              <input
                                type="email"
                                id="emailInput"
                                name="email"
                                required
                                onChange={(e) => {
                                  setemail(e.target.value);
                                }}
                                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                className={`${Styles.inebt}`}
                                placeholder="enter your email"
                              />
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              onClick={handleVerify}
                              className={`${Styles.sbtn}`}
                              type="button"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className="font12 ">
                        (Click stars to vote4.9 by 8 shoppers)
                      </p>
                    </div>
                  </div>
                  <div className={`px-2 py-2 ${Styles.hr}`}>
                    <h2 className="font18 fontw5 m-0">Offer Types</h2>
                  </div>
                  <div className={`px-2 py-2 ${Styles.hr}`}>
                    <div className="d-flex row mb-2">
                      <div className="col-2  d-flex justify-content-center">
                        <input
                          checked={discontType === "none"}
                          onChange={() => {
                            setdiscontType("none");
                          }}
                          type="checkbox"
                          className="inputri"
                        />
                      </div>
                      <div className="col-6">
                        <p className="m-0  ">all</p>
                      </div>
                    </div>
                    <div className="d-flex row mb-2">
                      <div className="col-2  d-flex justify-content-center">
                        <input
                          checked={discontType === "coupon"}
                          onChange={() => {
                            setdiscontType("coupon");
                          }}
                          type="checkbox"
                          className="inputri"
                        />
                      </div>
                      <div className="col-6">
                        <p className="m-0  ">Coupons</p>
                      </div>
                    </div>
                    <div className="d-flex row mb-2">
                      <div className="col-2 d-flex justify-content-center">
                        <input
                          checked={discontType === "deal"}
                          onChange={() => {
                            setdiscontType("deal");
                          }}
                          type="checkbox"
                          className="inputri"
                        />
                      </div>
                      <div className="col-6">
                        <p className="m-0  ">Deals</p>
                      </div>
                    </div>

                    <div className="d-flex row mb-2">
                      <div className="col-2 d-flex justify-content-center">
                        <input
                          checked={discontType === "discount"}
                          onChange={() => {
                            setdiscontType("discount");
                          }}
                          type="checkbox"
                          className="inputri"
                        />
                      </div>
                      <div className="col-6">
                        <p className="m-0  ">Discount</p>
                      </div>
                    </div>
                  </div>
                  <div className={`px-2 py-2 ${Styles.hr}`}>
                    <h2 className="font18 fontw5 m-0">
                      About {storeData.name}
                    </h2>
                  </div>
                  <div className={`px-2 py-2 `}>
                    <p>{storeData.about}</p>
                  </div>
                </div>
                <div className={`mt-3 ${Styles.baner}`}>
                  <a
                    onClick={() => {
                      window.open(`${storeData.link}`, "_blank");
                    }}
                    border="0"
                    alt="Religious Jewelry"
                  >
                    <img className={`${Styles.baner}`} src={storeData.banner} />
                  </a>
                </div>
                <div className={`mt-3 ${Styles.storedetails}`}>
                  <div className={`px-2 py-2 ${Styles.hr}`}>
                    <h2 className="font18 fontw5 m-0">Related Stores</h2>
                  </div>
                  <div className="px-1 py-3 d-flex justify-content-between flex-wrap ">
                    {storebc.length > 0
                      ? storebc.map((item) => (
                          <a
                            href={`/store/${item.slug}`}
                            className={`mb-2 ${Styles.cardstore}`}
                          >
                            <img
                              className={`${Styles.cardstoreimg}`}
                              src={`https://backend.discountsmagnet.com/uploads/${item.upload_img}`}
                              alt="img here"
                            />
                          </a>
                        ))
                      : "Loading"}
                  </div>
                </div>
              </div>
              <div className={`${Styles.mobile} mb-4`}>
                <div className="d-flex flex-wrap justify-content-between justify-content-sm-between">
                  <div onClick={() => {}} className={`${Styles.maimg2} `}>
                    <img
                      src={`https://backend.discountsmagnet.com/uploads/${storeData.upload_img}`}
                    />
                  </div>
                  <div
                    className={`d-flex flex-wrap ms-3 ms-md-0 align-items-center ${Styles.maimg4}`}
                  >
                    <div className="d-flex flex-wrap align-items-center ">
                      <h2 className="font28-md fontw7 m-0 me-5">
                        {storeData.name}
                      </h2>
                      <CustomRating className="me-2" value={ratingValuea} />
                      <p className="m-0 font14">(Rating {ratingValuea})</p>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    window.open(`${storeData.link}`, "_blank");
                  }}
                  className={`mt-3 ${Styles.storebtn}`}
                >
                  <span className="me-1">
                    <Icon
                      style={{ fontSize: "23px" }}
                      icon="clarity:store-line"
                    />
                  </span>
                  Visit Store
                </button>
              </div>
            </div>
            <div className="col-md-12 col-lg-9  ">
              <div className="pb-50">
                <div className={`${Styles.desktop}`}>
                  <div className={`d-flex align-items-center  `}>
                    <h2 className="font32 fontw7 m-0 me-5">
                      {storeData.name + " "}
                      {/* { storeData.key_word == storeData.key_word === null ? "" : storeData.key_word} */}
                    </h2>
                    <div className="d-flex align-items-center">
                      <CustomRating className="me-2" value={ratingValuea} />
                      <p className="m-0 font14">(Rating {ratingValuea})</p>
                    </div>
                  </div>
                </div>

                <p className="font16">{storeData.about}</p>
              </div>
              <div className="pb-50">
                {offerData.length > 0 &&
                  offerData.map((item) => {
                    if (item.discount_type === "coupon") {
                      return (
                        <StoreCoupon
                          id={item.id}
                          used={item.clicks}
                          status={item.coupon_status}
                          thumbnail={item.thumbnail}
                          description={item.description}
                          title={item.title}
                          coupon_code={item.coupon_code}
                          link={item.link}
                          discount_type={item.discount_type}
                        />
                      );
                    }
                    return null; // Skip rendering if not a coupon
                  })}

                {offerData.length > 0 &&
                  offerData.map((item) => {
                    if (item.discount_type == "discount") {
                      return (
                        <StoreCoupon
                          id={item.id}
                          thumbnail={item.thumbnail}
                          used={item.clicks}
                          status={item.coupon_status}
                          description={item.description}
                          title={item.title}
                          coupon_code={item.coupon_code}
                          link={item.link}
                          discount_type={item.discount_type}
                        />
                      );
                    }
                    return null; // Skip rendering if not a coupon
                  })}

                <div
                  className={` p-3 mb-3   d-md-flex justify-content-between align-items-center ${Styles.infocon}`}
                >
                  <div>
                    <p className="wit font18 m-0">Never Miss A Deals!!</p>
                    <p className="wit font16 fontw2  m-0">
                      Get our top coupon codes directly to your inbox.
                    </p>
                  </div>
                  <div
                    className={`d-flex justify-content-between align-items-center ${Styles.inputinfo}`}
                  >
                    <input className={`me-md-1 ${Styles.infoin}`} />
                    <button className={`${Styles.infobtn}`}>
                      Subscribe Now
                    </button>
                  </div>
                </div>
                {offerData.length > 0 &&
                  offerData.map((item) => {
                    if (item.discount_type === "deal") {
                      return (
                        <StoreCoupon
                          id={item.id}
                          thumbnail={item.thumbnail}
                          used={item.clicks}
                          description={item.description}
                          status={item.coupon_status}
                          title={item.title}
                          coupon_code={item.coupon_code}
                          link={item.link}
                          discount_type={item.discount_type}
                        />
                      );
                    }
                    return null; // Skip rendering if not a coupon
                  })}
              </div>
              <div>
                <h2 className=" pb-50 font32 fontw7">
                  {storeData.name} <span className="fontw4">Coupon FAQ’s</span>{" "}
                </h2>
                <h2 className=" font20 fontw4">
                  Where can I find {storeData.name} coupons?
                </h2>
                <p className=" font16 fontw4 mb-4">
                  {storeData.name} offers coupons and promotional codes which
                  you can find listed on this page. Look for coupon codes marked
                  with the green verified label for today's active{" "}
                  {storeData.name} promo codes. You can also find sales and
                  other promotions for {storeData.name} here as well.{" "}
                </p>
                <h2 className=" font20 fontw4">
                  What's today's best {storeData.name} coupon?
                </h2>
                <p className=" font16 fontw4 mb-4">
                  Today's biggest {storeData.name} Discount Code is for $51 off.
                  The coupons you see at the top of this page will always show
                  the best {storeData.name} discount codes first. Generally, the
                  best codes are "store-wide" deals that can be used on any
                  purchase at {storeData.name}. Also, look for the Sam Villa
                  promo codes with the biggest discount percentage.{" "}
                </p>
                <h2 className=" font20 fontw4">
                  How do I use my {storeData.name} discount code?
                </h2>
                <p className=" font16 fontw4 mb-4">
                  First, copy the discount code by clicking the code on this
                  page. Then head to {storeData.name} website at{" "}
                  {storeData.name}.com and enter the code in the coupon code
                  entry box during checkout. You will see either a confirmation
                  message of your savings or an error if the code did not work.{" "}
                </p>
                <h2 className=" font20 fontw4">
                  How often does {storeData.name} offer online coupons?
                </h2>
                <p className=" font16 fontw4 mb-4">
                  {storeData.name} issues coupon codes a little less frequently
                  than other websites. We check for new {storeData.name} codes
                  frequently, so just check back this page to find the latest
                  available {storeData.name} coupons.{" "}
                </p>
                <h2 className=" font20 fontw4">
                  My {storeData.name} promo code didn't work. What can I do?
                </h2>
                <p className=" font16 fontw4 mb-4">
                  Some coupon codes have special requirements or exceptions.
                  Click "view restrictions" next to the coupon code on this page
                  to learn more. If necessary, try multiple {storeData.name}{" "}
                  codes on this page until you find one that redeems a discount.
                </p>
                <h2 className=" font20 fontw4">
                  How many coupons is {storeData.name} offering today?
                </h2>
                <p className=" font16 fontw4 mb-4">
                  Currently, {storeData.name} is running 5 promo codes and 13
                  total offers, redeemable for savings at their website{" "}
                  {storeData.name}.com.
                </p>
              </div>
            </div>
            <div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Store;
