import Styles from "./Networks.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Icon } from "@iconify/react";
const Networks = () => {
  const [network, Setaddnetwork] = useState("");
  const [networkget, Setaddnetworkget] = useState("");
  const [editnetwork, Seteditnetwork] = useState("");
  const [refresh, setrefresh] = useState("");
  useEffect(() => {
    axios
      .get("https://backend.discountsmagnet.com/network")
      .then((response) => {
        console.log(response.data);
        Setaddnetworkget(response.data.details);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refresh]);
  const handleModalClose = () => {
    Seteditnetwork("");
  };
  return (
    <>
      <NotificationContainer />
      <div className="px-5 w-100 pt-3">
        <div>
          <p className="font18">
            Dashboard / <span className="fontw6">Networks</span>
          </p>
        </div>
        <div className="d-flex  align-items-center">
          <h2 className="font30 me-2">Networks</h2>
          <p className="m-0 font18">(No. Total Stores: 20)</p>
        </div>
        <div
          className={`row d-flex justify-content-evenly  p-3 ${Styles.main}`}
        >
          <div className="col-4">
            <input className={` ${Styles.input1}`} />
          </div>
          <div className="col-3">
            <select className={` ${Styles.input2}`}>
              <option>asa</option>
            </select>
          </div>
          <div className="col-3 ">
            <select className={`${Styles.input3}`}>
              <option>asa</option>
            </select>
          </div>
          <div className="col-2">
            <button
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              className={` ${Styles.btn}`}
            >
              Add Store
            </button>

            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                      Add Networks
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row">
                      <div className="col-8">
                        <input
                          className={` ${Styles.input1}`}
                          onChange={(e) => {
                            Setaddnetwork(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-4">
                        {" "}
                        <button
                          onClick={() => {
                            axios
                              .post(
                                "https://backend.discountsmagnet.com/networks",
                                {
                                  name: network,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                      "access token"
                                    )}`,
                                  },
                                }
                              )
                              .then((response) => {
                                console.log(response.data);
                                setrefresh(!refresh);
                                if (response.data.status === 200) {
                                  NotificationManager.success(
                                    "Success message",
                                    "networks Added Successfully"
                                  );
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          }}
                          className={`${Styles.addbtnnr}`}
                          type="button"
                          data-bs-dismiss="modal"
                        >
                          Add Networks
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={` row p-3 mt-3 ${Styles.main}`}>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">TITLE</th>
                <th scope="col">STORE(no)</th>

                <th scope="col">ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {networkget.length > 0 &&
                networkget.map((item) => {
                  return (
                    <tr>
                      <th scope="row">{item.id}</th>
                      <td>{item.network_name}</td>
                      <td>{item.num_store}</td>

                      <td>
                        {" "}
                        <span>
                          <a
                            onClick={() => {
                              axios
                                .delete(
                                  `https://backend.discountsmagnet.com/network/${item.id}`,

                                  {
                                    headers: {
                                      Authorization: `Bearer ${localStorage.getItem(
                                        "access token"
                                      )}`,
                                    },
                                  }
                                )
                                .then((response) => {
                                  console.log(response.data);
                                  setrefresh(!refresh);
                                  if (response.data.status === 200) {
                                    // NotificationManager.success('Success message', 'Category Added Successfully');
                                    // navigate("/dashboard/affiliatestores")
                                  }
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            }}
                          >
                            <span>
                              <Icon icon="fluent:delete-16-regular" />
                            </span>
                          </a>
                        </span>
                        <span>
                          <a
                            data-bs-toggle="modal"
                            data-bs-target={`#exampleModal${item.id}`}
                          >
                            <Icon icon="ep:edit" />
                          </a>
                          <div
                            className="modal fade"
                            id={`exampleModal${item.id}`}
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                            onHide={handleModalClose}
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h1
                                    class="modal-title fs-5"
                                    id="exampleModalLabel"
                                  >
                                    edit Categories
                                  </h1>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div class="modal-body">
                                  <div className="row">
                                    <div className="col-8">
                                      <input
                                        defaultValue={item.network_name}
                                        onChange={(e) => {
                                          Seteditnetwork(e.target.value);
                                        }}
                                        className={` ${Styles.input1}`}
                                      />
                                    </div>
                                    <div className="col-4">
                                      <button
                                        onClick={() => {
                                          axios
                                            .put(
                                              `https://backend.discountsmagnet.com/network/${item.id}`,
                                              {
                                                network_name: editnetwork,
                                              },
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${localStorage.getItem(
                                                    "access token"
                                                  )}`,
                                                },
                                              }
                                            )
                                            .then((response) => {
                                              console.log(response.data.status);
                                              setrefresh(!refresh);
                                              if (
                                                response.data.status === 200
                                              ) {
                                                NotificationManager.success(
                                                  "Success message",
                                                  "Category edit Successfully"
                                                );
                                              }
                                            })
                                            .catch((error) => {
                                              console.log(error);
                                            });
                                        }}
                                        className={` ${Styles.addbtnnr}`}
                                        type="button"
                                        data-bs-dismiss="modal"
                                      >
                                        Add Categories
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="row  mt-3  ">
          <nav aria-label="Page d-flex justify-content-end navigation ">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  1
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  2
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  3
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
export default Networks;
